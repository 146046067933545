import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
  const [orderId, setOrderId] = useState("");
  const [typeOrder, setTypeOrder] = useState("");
  const [token, setToken] = useState("");

  
  return (
    <StateContext.Provider
      value={{
        orderId,
        setOrderId,
        typeOrder,
        setTypeOrder,
        token,
        setToken,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
