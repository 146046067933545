import React, { useState } from "react";
import { BsFillLockFill } from "react-icons/bs";
import { TextField, Select, MenuItem, Autocomplete } from "@mui/material";
import { useGetProvincesQuery } from "../../redux/services/transportApiCore";

const Sender = ({
  senderName,
  senderPhone,
  senderAddress,
  wardCode,
  district,
  province,
}) => {
  const [name, setName] = useState(senderName);
  const [phone, setPhone] = useState(senderPhone);
  const [address, setAddress] = useState(senderAddress);
  const [wardCodeHold, setWardCodeHold] = useState(wardCode);
  const [districtHold, setDistrictHold] = useState(district);
  const [provinceHold, setProvinceHold] = useState(province);

  const { data: provincesData } = useGetProvincesQuery();
  // console.log(provincesData);
  // const { data: wardsData } = useGetWardsByDistrictIdQuery({ id: district });
  return (
    <div className="w-3/4">
      <h1 className="font-bold text-3xl">Shop/Sender</h1>
      <div className="w-full bg-white rounded-sm px-6 py-6 mt-8">
        <div className="w-full flex flex-row justify-between pb-4 items-center border-b-2 border-dashed border-gray-200">
          <h2 className="font-semibold text-2xl">Shop Information</h2>
          <p className="text-md text-gray-300 font-medium flex flex-row items-center justify-between">
            <BsFillLockFill /> Secure Server
          </p>
        </div>
        <div className="flex flex-col w-full mt-8 gap-6">
          <div className="flex flex-col justify-between gap-6">
            <TextField
              id="outlined-basic"
              label="Shop/Sender Name"
              variant="outlined"
              value={name}
            />
            <TextField
              id="outlined-basic"
              type="number"
              label="Phone Number"
              variant="outlined"
              value={phone}
            />
          </div>
          <div className="flex flex-row justify-between h-full border-b-2 border-dashed border-gray-200 pb-8 gap-4">
            <TextField
              id="outlined-basic"
              label="Address"
              variant="outlined"
              className="w-full"
              value={address}
            />
            {provincesData && (
              <Autocomplete
                value={
                  provincesData.find((p) => p.id === provinceHold)
                    ? provincesData.find((p) => p.id === provinceHold).name
                    : provinceHold
                    ? "Province not found"
                    : null
                }
                disablePortal
                id="combo-box-demo"
                options={provincesData?.map((province) => province.name)}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Province" />
                )}
              />
            )}
            {/* <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={ward}
              label="Ward"
              onChange={handleChangeWard}
              className="w-full"
            >
              <MenuItem value={10}>Phường Mường Thanh</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sender;
