import { configureStore, createSlice } from "@reduxjs/toolkit";
import { transportApiCoreSlice } from "./services/transportApiCore";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    orderId: null,
    orderType: null,
    server: "vi",
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setOrderId: (state, action) => {
      state.orderId = action.payload;
    },
    setOrderType: (state, action) => {
      state.orderType = action.payload;
    },
    setServer: (state, action) => {
      state.server = action.payload;
    },
  },
});

export const { setToken, setOrderId, setOrderType } = authSlice.actions;

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    [transportApiCoreSlice.name]: transportApiCoreSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(transportApiCoreSlice.api.middleware),
});
