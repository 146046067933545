import React, { useState } from "react";
import { BsFillLockFill } from "react-icons/bs";
import { TextField, Select, MenuItem } from "@mui/material";

const Receiver = ({recName, recPhone, recAddress, stated, surbur}) => {
  const [name, setName] = useState(recName)
  const [phone, setPhone] = useState(recPhone)
  const [address, setAddress] = useState(recAddress)
  const [district, setDistric] = useState(stated);
  const [ward, setWard] = useState(surbur);
  const handleChangeDistrict = (e) => setDistric(e.target.value);
  const handleChangeWard = (e) => setWard(e.target.value);
  return (
    <div className="w-3/4 my-8">
      <h1 className="font-bold text-3xl">Receiver</h1>
      <div className="w-full bg-white rounded-sm px-6 py-6 mt-8">
        <div className="w-full flex flex-row justify-between pb-4 items-center border-b-2 border-dashed border-gray-200">
          <h2 className="font-semibold text-2xl">Receiver Information</h2>
          <p className="text-md text-gray-300 font-medium flex flex-row items-center justify-between">
            <BsFillLockFill /> Secure Server
          </p>
        </div>
        <div className="flex flex-col w-full mt-8 gap-6">
          <div className="flex flex-col justify-between gap-6">
            <TextField
              id="outlined-basic"
              label="Receiver Name"
              variant="outlined"
              value={name}
            />
            <TextField
              id="outlined-basic"
              type="number"
              label="Phone Number"
              variant="outlined"
              value={phone}
            />
          </div>
          <div className="flex flex-row justify-between h-full border-b-2 border-dashed border-gray-200 pb-8 gap-4">
            <TextField
              id="outlined-basic"
              label="Address"
              variant="outlined"
              className="w-full"
              value={address}
            />
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultChecked
              value={district}
              label="District"
              onChange={handleChangeDistrict}
              className="w-full"
            >
              <MenuItem value={10}>Quận Thủ Dức, Hồ Chí Minh</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={ward}
              label="Ward"
              onChange={handleChangeWard}
              className="w-full"
            >
              <MenuItem value={10}>Phường Mường Thanh</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Receiver;
