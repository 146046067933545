import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";
import { Login, Order } from "./pages";
import withAuth from "./common/authenticate";

const OrderWithAuth = withAuth(Order);

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/try" element={<Login />} />
        <Route exact path="/home" element={<OrderWithAuth />} />
      </Routes>
    </Router>
  );
}

export default App;
