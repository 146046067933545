import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { GoGlobe } from "react-icons/go";
import { RiCompass2Fill } from "react-icons/ri";

import { Navbar, Card } from "../component";
import connect from "../assets/add.png";

import { Error, Loading } from "../component";
import { OrderDetails, OrderCreate } from "../component/Order";
import { useGetListOrderQuery } from "../redux/services/transportApiCore";

import { setOrderId, setOrderType } from "../redux/store";
import { useDispatch } from "react-redux";

const Order = () => {
  const dispatch = useDispatch();
  const [openCreate, setOpenCreate] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [server, setServer] = useState("vi");

  const handleOpenDetail = (id, type) => {
    setOpenDetail((prev) => !prev);
    dispatch(setOrderId(id));
    dispatch(setOrderType(type));
  };

  const handleOpenCreate = () => setOpenCreate((prev) => !prev);

  const { data, isFetching, error } = useGetListOrderQuery({ type: server });
  // if(data) console.log(data)
  if (isFetching) return <Loading title="Loading Orders..." />;
  if (error) return <Error />;
  return (
    <div className="bg-slate-100">
      <Navbar />
      <div className="fixed md:flex md:flex-col left-0 top-40 w-20 md:bg-gray-100">
        <button
          onClick={() => setServer("au")}
          className={`${server === "au" ? 'bg-gray-300 text-white' : 'text-black'} m-2 dark:text-white rounded-md w-full hover:bg-gray-300 h-8 hover:text-white`}
        >
          AU
        </button>
        <button
          onClick={() => setServer("vi")}
          className={`${server === "vi" ? 'bg-gray-300 text-white' : 'text-black'} m-2 dark:text-white rounded-md w-full hover:bg-gray-300 h-8 hover:text-white`}
        >
          VI
        </button>
      </div>
      <div className="tabs px-40">
        <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400">
          <li className="mr-2">
            <a
              href="#"
              className="inline-block px-4 py-3 text-white bg-gray-300 rounded-lg active"
              aria-current="page"
            >
              New (89)
            </a>
          </li>
          <li className="mr-2">
            <a
              href="#"
              className="inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white"
            >
              Prepared (65)
            </a>
          </li>
          <li className="mr-2">
            <a
              href="#"
              className="inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white"
            >
              Ready for shipping (24)
            </a>
          </li>
          <li className="mr-2">
            <a
              href="#"
              className="inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white"
            >
              In transit (12)
            </a>
          </li>
          <li>
            <a className="inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white">
              Delivered (131)
            </a>
          </li>
          <li>
            <a className="inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white">
              Failed (0)
            </a>
          </li>
        </ul>
      </div>

      <div className="grid grid-cols-3 gap-4 px-40 py-8">
        <OrderCreate open={openCreate} handleClose={handleOpenCreate} />
        <div
          className="bg-white h-96 w-96 shadow-lg my-1 rounded-lg flex flex-col"
          onClick={handleOpenCreate}
        >
          <div className="w-full flex flex-col-reverse items-center">
            <div className="w-1/2 h-4/5 shadow-xl">
              <img
                src={connect}
                alt="connect"
                className="bg-white h-full w-full"
              />
            </div>
          </div>
          <div className="flex flex-col items-center pt-8">
            <h2 className="font-semibold text-2xl">Add new shipment</h2>
            <p className="text-gray-500">
              Fill out the quick form to create a new shipment
            </p>
            <div className="bg-[#3519E7] h-16 w-16 mt-4 rounded-md flex justify-center items-center">
              <AiOutlinePlus />
            </div>
          </div>
        </div>

        {openDetail && (
          <OrderDetails open={openDetail} handleClose={handleOpenDetail} />
        )}

        {data?.data.orders.map((val, idx) => (
          <Card
            key={idx}
            id={val.code}
            createdAt={val.createdAt}
            updatedAt={val.updatedAt}
            senderName={
              val.type === "au"
                ? val.sender_addresses_au.senderName
                : val.receiver_addresses_vi.senderName
            }
            consigneeName={
              val.type === "au"
                ? val.sender_addresses_au.consigneeName
                : val.receiver_addresses_vi.consigneeName
            }
            totalKgEst={val.totalKgEst}
            estPrice={val.estPrice}
            type={val.type}
            handleDetails={() => handleOpenDetail(val.code, val.type)}
          />
        ))}
      </div>
    </div>
  );
};

export default Order;
