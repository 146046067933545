import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../context/ContextProvider";
import { setToken } from "../redux/store";
import { useDispatch } from "react-redux";


const Login = () => {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    //Set loading here
    if (!phone || !password) {
      alert("Please fill all the fields");
      return;
    }
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        "https://adminsdkvanchuyen.myepis.cloud/adminvanchuyen/auth/login",
        { phone, password },
        config
      );
      localStorage.setItem("userData", data.data.token);
      dispatch(setToken(localStorage.getItem("userData")));

      //set loading here falsy
      navigate("/home");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      className="m-0 p-0 bg-indigo-500 h-screen overflow-hidden"
      style={{ backgroundImage: "linear-gradient(120deg, #2980b9, #8e44ad)" }}
    >
      <div className="center">
        <h1>Login</h1>
        <form onSubmit={handleSubmit}>
          <div className="txt_field">
            <input
              type="text"
              required
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <span></span>
            <label>Username</label>
          </div>
          <div className="txt_field">
            <input
              type="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span></span>
            <label>Password</label>
          </div>
          <input type="submit" value="Login" className="-mt-5 mb-20" />
        </form>
      </div>
    </div>
  );
};

export default Login;
