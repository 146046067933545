import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";

import { Sender, Summary, Receiver, Product } from "./index";
import { useGetOrderByIdQuery } from "../../redux/services/transportApiCore";
import { Error, Loading } from "../index";

import { useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrderDetails = ({ open, handleClose }) => {
  const { orderId, orderType } = useSelector((state) => state.auth);

  const { data, isFetching, error } = useGetOrderByIdQuery({
    id: orderId,
    type: orderType,
  });

  if (error) console.log(error);
  if (isFetching) return <Loading title="Loading Details..." />;

  return error ? (
    <Error />
  ) : (
    <Dialog
      fullScreen
      TransitionComponent={Transition}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className="w-full bg-slate-100">
        <DialogContentText
          id="alert-dialog-description"
          className="h-full bg-slate-100"
        >
          <Summary />
          <Sender
            senderName={
              orderType === "au"
                ? data?.sender_addresses_au.senderName
                : data?.sender_addresses_vi.senderName
            }
            senderPhone={
              orderType === "au"
                ? data?.sender_addresses_au.senderPhone
                : data?.sender_addresses_vi.senderPhone
            }
            senderAddress={
              orderType === "au"
                ? data?.sender_addresses_au.address
                : data?.sender_addresses_vi.address
            }
            wardCode={
              orderType === "au"
                ? data?.sender_addresses_au.postCode
                : data?.sender_addresses_vi.wardCode
            }
            district={
              orderType === "au"
                ? data?.sender_addresses_au.suburb
                : data?.sender_addresses_vi.districtId
            }
            province={
              orderType === "au"
                ? data?.sender_addresses_au.state
                : data?.sender_addresses_vi.provinceId
            }
          />
          <Receiver
            consigneeName={
              orderType === "au"
                ? data?.sender_addresses_au.senderName
                : data?.sender_addresses_vi.senderName
            }
            consigneePhone={
              orderType === "au"
                ? data?.sender_addresses_au.senderPhone
                : data?.sender_addresses_vi.senderPhone
            }
            consigneeAddress3={
              orderType === "au"
                ? data?.sender_addresses_au.address
                : data?.sender_addresses_vi.address
            }
            wardCode={
              orderType === "au"
                ? data?.sender_addresses_au.postCode
                : data?.sender_addresses_vi.wardCode
            }
            district={
              orderType === "au"
                ? data?.sender_addresses_au.suburb
                : data?.sender_addresses_vi.districtId
            }
            province={
              orderType === "au"
                ? data?.sender_addresses_au.state
                : data?.sender_addresses_vi.provinceId
            }
          />
          <Product orderItems={data?.order_items} />
        </DialogContentText>
      </DialogContent>
      <DialogActions className="">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderDetails;
