import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const withAuth = (Component) => (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData && location.pathname === "/try") {
      navigate("/home");
    } else if (!userData && location.pathname !== "/try") {
      navigate("/try");
    } else if (userData && location.pathname === "/") {
      navigate("/home");
    }
  }, [location, navigate]);

  return localStorage.getItem("userData") ? <Component {...props} /> : null;
};

export default withAuth;
