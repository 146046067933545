import React from "react";

const Product = ({orderItems}) => {

  return (
    <div className="w-3/4 h-full">
      <h1 className="font-bold text-3xl text-black">Products</h1>
      <div className="w-full bg-white rounded-sm px-6 py-6 mt-8 flex flex-col overflow-y-auto h-96">
        <div className="flex flex-row justify-between items-center gap-10 border-b-1 border-gray-500 pb-4">
          <h2 className="font-medium text-2xl text-black">Product</h2>
          <div className="flex flex-row justify-between items-center w-3/5">
            <h2 className="font-medium text-lg text-black">Weight</h2>
            <h2 className="font-medium text-lg text-black">Quantity</h2>
            <h2 className="font-medium text-lg text-black">Product Price</h2>
          </div>
        </div>
        {orderItems.map((val, idx) => (
          <div key={idx} className="flex flex-row justify-between items-center gap-10 border-b-1 border-gray-500 pb-4">
            {/* <img src={val.img} alt="product-images" className="h-10 w-10" /> */}
            <h2 className="font-medium text-xl">{val.productName}</h2>
            <div className="flex flex-row justify-between items-center w-3/5">
              <h2 className="font-medium text-lg">{val.weight}</h2>
              <h2 className="font-medium text-lg">{val.quantity}</h2>
              <h2 className="font-medium text-lg">{val.price}</h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Product;
