import React from "react";
import { TextField, Button, Select, MenuItem } from "@mui/material";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useGetOrderHistoryQuery } from "../../redux/services/transportApiCore";
import { useSelector } from "react-redux";

const Summary = () => {
  const { orderId } = useSelector((state) => state.auth);
  const { data, isFetching, error } = useGetOrderHistoryQuery({ id: orderId });
  const generateDateTime = (str) => {
    const dateObj = new Date(str);

    const formattedDate = dateObj.toLocaleDateString("en-GB");
    const formattedTime = dateObj.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    const formattedDateTime = `${formattedTime} - ${formattedDate}`;
    return formattedDateTime
  };
  return (
    <div className="float-right w-1/4 px-4">
      <div className="flex flex-row justify-between items-center border-b-1 border-gray-500 pb-8">
        <h2 className="font-bold text-2xl">Order Summary</h2>
      </div>
      <div className="flex flex-row justify-between items-center my-4 border-b-1 border-gray-500 pb-4">
        <TextField
          id="outlined-basic"
          label="Shop/Sender Name"
          variant="outlined"
          defaultValue="ADFK6498"
        />
        <Button variant="outlined">Apply</Button>
      </div>
      <div className="flex flex-col">
        <div className="w-full border-b-1 border-gray-500 pb-4 h-96 overflow-y-auto">
          <div className="flex flex-row justify-between items-center">
            <h4 className="font-semibold text-lg text-red-400">
              Order History
            </h4>
            <p className="font-thin text-sm text-gray-400 hover:underline">
              See All
            </p>
          </div>
          {data &&
            Object.keys(data).map((key) => {
              const val = data[key];

              return (
                <Card key={key} sx={{ display: "flex", marginBottom: "8px" }}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <Typography component="div" variant="h6">
                        Updated By: {val.provider || "No Name"}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                      >
                        {generateDateTime(val.createdAt)}
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              );
            })}
        </div>
        <div className="w-full border-b-1 border-gray-500 mt-4 pb-4">
          <div className="flex flex-row justify-between items-center">
            <h4 className="font-semibold text-lg text-gray-700">Status</h4>
          </div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={1}
            label="Payment Option"
            className="w-full"
          >
            <MenuItem value={1}>Paid Recipients</MenuItem>
            <MenuItem value={2}>Sender Pays</MenuItem>
          </Select>
        </div>
        <div className="flex flex-col my-4">
          <div className="flex flex-row justify-between items-center">
            <h3 className="font-semibold text-sm text-gray-400">Subtotal:</h3>
            <p className="font-thin text-sm">31.000 VND</p>
          </div>
          <div className="flex flex-row justify-between items-center">
            <h3 className="font-semibold text-sm text-gray-400">
              Delivery package:
            </h3>
            <p className="font-thin text-sm">31.000 VND</p>
          </div>
          <div className="flex flex-row justify-between items-center">
            <h3 className="font-semibold text-sm text-gray-400">Tax:</h3>
            <p className="font-thin text-sm">31.000 VND</p>
          </div>
          <div className="flex flex-row justify-between items-center">
            <h3 className="font-semibold text-sm text-gray-400">Total:</h3>
            <p className="font-bold text-xl">31.000 VND</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
