import React, { useState } from "react";
import { GiHammerSickle } from "react-icons/gi";
import { SlTrophy } from "react-icons/sl";
import { BsCircle, BsSquare } from "react-icons/bs";
import { MdOutlineMessage, MdLocalPhone } from "react-icons/md";

const Card = ({
  id,
  createdAt,
  updatedAt,
  senderName,
  consigneeName,
  totalKgEst,
  estPrice,
  handleDetails,
}) => {

  return (
    <>
      <div
        className="bg-white h-96 w-96 shadow-lg my-1 rounded-lg flex flex-col justify-around"
        onClick={handleDetails}
      >
        <div className="flex flex-row justify-between mt-2 mx-8">
          <div className="flex flex-col">
            <p className="text-sm text-gray-400">Shipment Code</p>
            <h2 className="font-medium truncate w-36">{id}</h2>
          </div>
          <div className="flex flex-row justify-between">
            <div className="bg-gray-100 w-10 h-10 rounded-md flex justify-center items-center">
              <GiHammerSickle />
            </div>
            <div className="bg-gray-100 w-10 h-10 ml-3 rounded-md flex justify-center items-center">
              <SlTrophy />
            </div>
          </div>
        </div>

        <div className="flex flex-row items-start mx-8 h-18 border-b-1 border-t-1 py-4">
          <div className="flex flex-col justify-between items-center h-full">
            <BsCircle />
            <div className="line-between bg-black h-10 w-[1px]"></div>
            <BsSquare />
          </div>
          <div className="flex flex-col ml-8 h-full justify-between">
            <p className="text-sm text-gray-400">Created At</p>
            <h2 className="font-medium">{createdAt}</h2>
            <p className="text-sm text-gray-400">Finished At</p>
            <h2 className="font-medium">{updatedAt}</h2>
          </div>
        </div>
        <div className="flex flex-row h-10 px-8 justify-between">
          <div className="flex flex-col items-center">
            <p className="text-sm text-gray-400">Sender</p>
            <h2 className="font-medium">{senderName}</h2>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-sm text-gray-400">Weight</p>
            <h2 className="font-medium">{totalKgEst}</h2>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-sm text-gray-400">Price</p>
            <h2 className="font-medium">{estPrice}</h2>
          </div>
        </div>
        <div className="flex flex-row justify-between h-10 px-8">
          <div className="flex flex-col justify-start items-start">
            <p className="text-sm text-gray-400">Reciever</p>
            <h2 className="font-medium">{consigneeName}</h2>
          </div>
          <div className="flex flex-row justify-around items-end w-1/2">
            <MdOutlineMessage className="h-full" />
            <MdLocalPhone className="h-full" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
