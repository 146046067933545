import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const transportApiCore = createApi({
  reducerPath: "transportApiCore",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://adminsdkvanchuyen.myepis.cloud/",
    prepareHeaders: (headers, { getState }) => {
      // Retrieve the token from the Redux store instead of localStorage
      const token = getState().auth.token || localStorage.getItem("userData");

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      headers.set("x-api-key", "6b209e1a-9e63-4638-893d-ee30c1547ee0");

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getListOrder: builder.query({
      query: ({type}) => `adminvanchuyen/orders?type=${type}`,
    }),
    getOrderById: builder.query({
      query: ({ id, type }) => `adminvanchuyen/order/${id}/${type}`,
      transformResponse: (response) => response.data,
    }),
    getOrderHistory: builder.query({
      query: ({ id }) => `adminvanchuyen/status/${id}`,
      transformResponse: (response) => response.data,
    }),
    getProvinces: builder.query({
      query: () => "adminvanchuyen/location/provinces",
      transformResponse: (response) => response.data,
    }),
    getWardsByDistrictId: builder.query({
      query: ({id}) => `adminvanchuyen/location/${id}/ward`,
      transformResponse: (response) => response.data,
    })
  }),
});

export const {
  useGetListOrderQuery,
  useGetOrderByIdQuery,
  useGetOrderHistoryQuery,
  useGetProvincesQuery,
  useGetWardsByDistrictIdQuery,
} = transportApiCore;

export const transportApiCoreSlice = {
  name: transportApiCore.reducerPath,
  reducer: transportApiCore.reducer,
  api: transportApiCore,
};
